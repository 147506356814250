var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"barcodecontainer d-flex align-items-center justify-content-center p-5 flex-column"},[_c('b-img',{class:{
      'full-height': _vm.type == 'barcode',
      'full-width': _vm.type == 'qrcode'
    },attrs:{"src":_vm.type == 'barcode'
        ? _vm.data.barcodePath
        : _vm.type == 'qrcode'
        ? _vm.data.qrcodePath
        : ''}}),_c('b-button',{staticClass:"mt-3",on:{"click":_vm.sendImage}},[_vm._v("บันทึกและปิด")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }