<template>
  <div
    class="barcodecontainer d-flex align-items-center justify-content-center p-5 flex-column"
  >
    <b-img
      :class="{
        'full-height': type == 'barcode',
        'full-width': type == 'qrcode'
      }"
      :src="
        type == 'barcode'
          ? data.barcodePath
          : type == 'qrcode'
          ? data.qrcodePath
          : ''
      "
    >
    </b-img>
    <b-button class="mt-3" @click="sendImage">บันทึกและปิด</b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lineProfile: {},
      customerProfile: {},
      id: this.$route.query.id.toLowerCase(),
      type: this.$route.query.type.toLowerCase(),
      state: "fail",
      data: {
        barcodePath: "",
        qrcodePath: ""
      },
      src: "",
      rerender: 1
    };
  },
  created() {
    this.getBarcode();
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_Barcode })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.checkTerm(profile.userId);
            });
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getBarcode() {
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", "S1112LIN05!");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/Payment/QRCode/${this.id}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.data = result.detail;
            this.src =
              this.type == "barcode"
                ? this.data.barcodePath
                : this.type == "qrcode"
                ? this.data.qrcodePath
                : "";
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getPath() {
      if (this.type == "barcode") {
        return this.data.barcodePath;
      } else if (this.type == "qrcode") {
        return this.data.qrcodePath;
      } else {
        return "";
      }
    },
    sendImage() {
      this.$liff
        .sendMessages([
          {
            type: "text",
            text:
              this.type == "barcode"
                ? `Barcode ชำระเงินของท่าน`
                : this.type == "qrcode"
                ? `QR code ชำระเงินของท่าน`
                : ""
          },
          {
            type: "image",
            originalContentUrl: this.src,
            previewImageUrl: this.src
          }
        ])
        .then(() => {
          this.$liff.closeWindow();
        });
    }
  }
};
</script>

<style scoped>
.barcodecontainer {
  width: 100vw;
  height: 100vh;
}
.img {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
</style>
